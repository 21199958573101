<template>
  <div class='lg:mt-8 px-2 lg:px-0'>
    <div class='flex flex-col lg:flex-row flex-wrap gap-y-12 lg:gap-y-24'>
      <div v-for='(usefulLink, index) in usefulLinks'
        :key='`usefulLink-${index}-${usefulLink.name}`'
        class='flex flex-col-reverse lg:flex-row justify-start gap-x-8 items-start w-full lg:w-1/2 flex-grow-0 flex-shrink-0'>
        <img :src='usefulLink.imageUrl'
          class='image-link block h-auto lg:h-48 w-full lg:w-60 border bg-white hover:shadow-md cursor-pointer'
          @click='imageLink(usefulLink.siteUrl)' />
        <div class=''>
          <h3 class='text-lg font-semibold mb-2 px-2'>{{ usefulLink.name }}</h3>
          <a :href='usefulLink.siteUrl' 
            target='_blank' 
            class='flex flex-row items-center justify-start gap-x-2 py-1 px-2 hover:bg-gray-100'>
            <globe-alt-icon class='h-5'/>
            <span
              class='block'>
              Homepage
            </span>
          </a>
          <a :href='`tel:${usefulLink.phoneNumber}`' 
            class='flex flex-row items-center justify-start gap-x-2 py-1 px-2 hover:bg-gray-100 hidden'>
            <phone-icon class='h-5'/>
            <span class=''>{{ usefulLink.phoneNumber }}</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { GlobeAltIcon, PhoneIcon } from '@vue-hero-icons/outline'

export default {
  name: 'EventLocation',
  components: {
    GlobeAltIcon,
    PhoneIcon
  },
  data () {
    return {
      usefulLinks: [
        {
          name:'Korea Tourism Organization',
          description: '',
          imageUrl: 'https://d3r2ol85dktaw0.cloudfront.net/conferences/koa2022/useful-links/korea_tourism_organization.jpg',
          siteUrl: 'https://kto.visitkorea.or.kr/eng.kto',
          phoneNumber: '+82-33-738-3000'
        },
        {
          name:'Gateway to Korea',
          description: '',
          imageUrl: 'https://d3r2ol85dktaw0.cloudfront.net/conferences/koa2022/useful-links/gateway_to_korea.jpg',
          siteUrl: 'https://www.korea.net/',
          phoneNumber: ''
        },
        {
          name:'Seoul Convention Bureau',
          description: '',
          imageUrl: 'https://d3r2ol85dktaw0.cloudfront.net/conferences/koa2022/useful-links/seoul _convention_bureau.jpg',
          siteUrl: 'https://www.miceseoul.com/index',
          phoneNumber: ''
        },
        {
          name:'Seoul Metropolitan Government',
          description: '',
          imageUrl: 'https://d3r2ol85dktaw0.cloudfront.net/conferences/koa2022/useful-links/seoul_metropolitan_government.jpg',
          siteUrl: 'http://english.seoul.go.kr/',
          phoneNumber: ''
        },
        {
          name:'Incheon Metropolitan City',
          description: '',
          imageUrl: 'https://d3r2ol85dktaw0.cloudfront.net/conferences/koa2022/useful-links/incheon_metropolitan_city.jpg',
          siteUrl: 'https://www.incheon.go.kr/en/index',
          phoneNumber: ''
        },
        {
          name:'Visit Seoul',
          description: '',
          imageUrl: 'https://d3r2ol85dktaw0.cloudfront.net/conferences/koa2022/useful-links/visit_seoul.jpg',
          siteUrl: 'https://www.visitseoul.net/index',
          phoneNumber: ''
        },
        {
          name:'Korea Immigration Service',
          description: '',
          imageUrl: 'https://d3r2ol85dktaw0.cloudfront.net/conferences/koa2022/useful-links/korea_immigration_service.jpg',
          siteUrl: 'https://www.immigration.go.kr/immigration_eng/index.do',
          phoneNumber: ''
        },
        {
          name:'Ministry of Foreign Affairs and Trade',
          description: '',
          imageUrl: 'https://d3r2ol85dktaw0.cloudfront.net/conferences/koa2022/useful-links/ministry_of_foreign_affairs_and_trade.jpg',
          siteUrl: 'https://www.mofa.go.kr/eng/index.do',
          phoneNumber: ''
        },
        {
          name:'Incheon International Airport',
          description: '',
          imageUrl: 'https://d3r2ol85dktaw0.cloudfront.net/conferences/koa2022/useful-links/incheon_international_airport.jpg',
          siteUrl: 'https://www.airport.kr/ap/en/index.do#',
          phoneNumber: ''
        }
      ],
    }
  },
  methods: {
    imageLink (link) {
      window.open(link, '_blank')
    }
  }
}
</script>
